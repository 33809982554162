export const DEFAULT_GRID_CONFIG_SIZES = [
  {
    minWidth: 2000,
    minTdDefaultWidth: '14rem',
    minStatusTdDefaultWidth: '18rem',
    minTdDateWidth: '18rem',
    minTdActionsWidth: '6rem',
  },
  {
    minWidth: 1700,
    minTdDefaultWidth: '10rem',
    minStatusTdDefaultWidth: '14rem',
    minTdDateWidth: '14rem',
    minTdActionsWidth: '6rem',
  },
  {
    minWidth: 0,
    minTdDefaultWidth: '9rem',
    minStatusTdDefaultWidth: '14rem',
    minTdDateWidth: '14rem',
    minTdActionsWidth: '5rem',
  },
];
