<ng-container *transloco="let t">
  <div class="date-filter">
    <div>
      <p-checkbox
        class="override-disabled"
        [binary]="true"
        [disabled]="false"
        [label]="t('blank')"
        [(ngModel)]="showNoValue"
        (onChange)="onChangeEmptyValue()"></p-checkbox>
    </div>
    <p-calendar
      dateFormat="dd-mm-yy"
      [disabled]="showNoValue"
      [iconDisplay]="'input'"
      [placeholder]="placeholder"
      [readonlyInput]="true"
      [selectionMode]="selectionMode"
      [showIcon]="true"
      [(ngModel)]="date"
      (onSelect)="onSelectDate(date)"></p-calendar>
  </div>
</ng-container>
