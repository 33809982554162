var l53 = {
  code: 'ne',
  week: {
    dow: 7,
    doy: 1 // The week that contains Jan 1st is the first week of the year.
  },
  buttonText: {
    prev: 'अघिल्लो',
    next: 'अर्को',
    today: 'आज',
    year: 'वर्ष',
    month: 'महिना',
    week: 'हप्ता',
    day: 'दिन',
    list: 'सूची'
  },
  weekText: 'हप्ता',
  allDayText: 'दिनभरि',
  moreLinkText: 'थप लिंक',
  noEventsText: 'देखाउनको लागि कुनै घटनाहरू छैनन्'
};
export { l53 as default };