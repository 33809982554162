import { StatesClasses } from './grid.constants';

export const enum InvoicesStatusStates {
  Paid = 'Paid',
  NotPaid = 'Not paid',
  Overdue = 'Overdue',
  Cancelled = 'Cancelled',
}

export const INVOICES_STATUS_MAP: { [key: string]: string } = {
  [InvoicesStatusStates.Paid.toLowerCase()]: StatesClasses.FernGreen,
  [InvoicesStatusStates.NotPaid.toLowerCase()]: StatesClasses.SunflowerYellow,
  [InvoicesStatusStates.Overdue.toLowerCase()]: StatesClasses.FirebrickRed,
  [InvoicesStatusStates.Cancelled.toLowerCase()]: StatesClasses.AshGrey,
};
