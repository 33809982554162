var l76 = {
  code: 'vi',
  week: {
    dow: 1,
    doy: 4 // The week that contains Jan 4th is the first week of the year.
  },
  buttonText: {
    prev: 'Trước',
    next: 'Tiếp',
    today: 'Hôm nay',
    year: 'Năm',
    month: 'Tháng',
    week: 'Tuần',
    day: 'Ngày',
    list: 'Lịch biểu'
  },
  weekText: 'Tu',
  allDayText: 'Cả ngày',
  moreLinkText(n) {
    return '+ thêm ' + n;
  },
  noEventsText: 'Không có sự kiện để hiển thị'
};
export { l76 as default };