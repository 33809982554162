var l46 = {
  code: 'ku',
  week: {
    dow: 6,
    doy: 12 // The week that contains Jan 1st is the first week of the year.
  },
  direction: 'rtl',
  buttonText: {
    prev: 'پێشتر',
    next: 'دواتر',
    today: 'ئەمڕو',
    year: 'ساڵ',
    month: 'مانگ',
    week: 'هەفتە',
    day: 'ڕۆژ',
    list: 'بەرنامە'
  },
  weekText: 'هەفتە',
  allDayText: 'هەموو ڕۆژەکە',
  moreLinkText: 'زیاتر',
  noEventsText: 'هیچ ڕووداوێك نیە'
};
export { l46 as default };