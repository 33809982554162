import { MockedResponse } from '../model';

interface InvoiceListItemDto {
  invoiceId: string;
  status: string;
  amount: string;
  billingAddress: string;
  dueDate: string;
  plannedPaymentDate: string;
  referenceNumber: string;
  issueDate: string;
  contactPerson: string;
  company: string;
  originalInvoiceNumber: string;
  creditNote: CreditNoteDto[];
  downloadUrl: string;
}

interface CreditNoteDto {
  invoiceId: string;
  status: string;
  amount: string;
  issueDate: string;
  company: string;
  originalInvoiceNumber: string;
  downloadUrl: string;
}

export const INVOICE_LIST: MockedResponse<InvoiceListItemDto> = {
  data: [
    {
      invoiceId: '462010019814',
      status: 'Overdue',
      amount: '1344.00 GBP',
      billingAddress: 'James Boulevard, London',
      dueDate: '2024-09-03T08:00:00Z',
      referenceNumber: '89465590',
      plannedPaymentDate: '2024-09-02T08:00:00Z',
      issueDate: '2024-09-01T08:00:00Z',
      contactPerson: 'Ryan Vaccaro',
      company: 'Opentech',
      originalInvoiceNumber: '',
      creditNote: [],
      downloadUrl: '',
    },
    {
      invoiceId: '462010020361',
      status: 'Cancelled',
      amount: '1516.80 GBP',
      billingAddress: 'James Boulevard, London',
      dueDate: '2024-09-28T08:00:00Z',
      referenceNumber: '89465590',
      plannedPaymentDate: '',
      issueDate: '2024-09-02T08:00:00Z',
      contactPerson: 'Corey Saris',
      company: 'Opentech',
      originalInvoiceNumber: '',
      creditNote: [
        {
          invoiceId: '462010020348',
          status: 'Credit note',
          amount: '-1516.80 GBP',
          issueDate: '2024-09-01T08:00:00Z',
          company: 'Opentech',
          originalInvoiceNumber: '462010020361',
          downloadUrl: '',
        },
      ],
      downloadUrl: '',
    },
    {
      invoiceId: '411010068698',
      status: 'Overdue',
      amount: '4000.00 NOK',
      billingAddress: 'James Boulevard, London',
      dueDate: '2024-08-28T08:00:00Z',
      referenceNumber: '89465590',
      plannedPaymentDate: '2024-08-28T08:00:00Z',
      issueDate: '2024-09-02T08:00:00Z',
      contactPerson: 'Anika Dias',
      company: 'Opentech',
      originalInvoiceNumber: '',
      creditNote: [],
      downloadUrl: '',
    },
    {
      invoiceId: '481010013217',
      status: 'Not paid',
      amount: '1592.93 EUR',
      billingAddress: 'James Boulevard, London',
      dueDate: '2024-09-28T08:00:00Z',
      referenceNumber: '89465590',
      plannedPaymentDate: '2024-09-27T08:00:00Z',
      issueDate: '2024-09-01T08:00:00Z',
      contactPerson: '',
      company: 'Opentech',
      originalInvoiceNumber: '',
      creditNote: [],
      downloadUrl: '',
    },
    {
      invoiceId: '462010000923',
      status: 'Not paid',
      amount: '1592.93 EUR',
      billingAddress: 'James Boulevard, London',
      dueDate: '2024-09-28T08:00:00Z',
      referenceNumber: '89465590',
      plannedPaymentDate: '2024-09-26T08:00:00Z',
      issueDate: '2024-09-02T08:00:00Z',
      contactPerson: 'Corey Saris',
      company: 'Opentech',
      originalInvoiceNumber: '',
      creditNote: [],
      downloadUrl: '',
    },
    {
      invoiceId: '462010000954',
      status: 'Not paid',
      amount: '1592.93 EUR',
      billingAddress: 'James Boulevard, London',
      dueDate: '2024-09-28T08:00:00Z',
      referenceNumber: '89465590',
      plannedPaymentDate: '2024-09-25T08:00:00Z',
      issueDate: '2024-09-01T08:00:00Z',
      contactPerson: '',
      company: 'Opentech',
      originalInvoiceNumber: '',
      creditNote: [],
      downloadUrl: '',
    },
    {
      invoiceId: '462010001222',
      status: 'Paid',
      amount: '1592.93 EUR',
      billingAddress: '',
      dueDate: '2024-09-28T08:00:00Z',
      referenceNumber: '89465590',
      plannedPaymentDate: '2024-09-24T08:00:00Z',
      issueDate: '2024-09-02T08:00:00Z',
      contactPerson: 'Anika Dias',
      company: 'Opentech',
      originalInvoiceNumber: '',
      creditNote: [],
      downloadUrl: '',
    },
    {
      invoiceId: '462010001224',
      status: 'Paid',
      amount: '1592.93 EUR',
      billingAddress: '',
      dueDate: '2024-09-28T08:00:00Z',
      referenceNumber: '89465590',
      plannedPaymentDate: '2024-09-20T08:00:00Z',
      issueDate: '2024-09-01T08:00:00Z',
      contactPerson: 'Ryan Vaccaro',
      company: 'Opentech',
      originalInvoiceNumber: '',
      creditNote: [],
      downloadUrl: '',
    },
    {
      invoiceId: '462010001226',
      status: 'Paid',
      amount: '1592.93 EUR',
      billingAddress: 'James Boulevard, London',
      dueDate: '2024-09-28T08:00:00Z',
      referenceNumber: '89465590',
      plannedPaymentDate: '2024-09-23T08:00:00Z',
      issueDate: '2024-09-02T08:00:00Z',
      contactPerson: 'Anika Dias',
      company: 'Opentech',
      originalInvoiceNumber: '',
      creditNote: [],
      downloadUrl: '',
    },
    {
      invoiceId: '462010002101',
      status: 'Overdue',
      amount: '2200.50 USD',
      billingAddress: '',
      dueDate: '2024-09-01T08:00:00Z',
      referenceNumber: '75549281',
      plannedPaymentDate: '2024-08-31T08:00:00Z',
      issueDate: '2024-08-25T08:00:00Z',
      contactPerson: 'Evelyn Parker',
      company: 'TechSolutions',
      originalInvoiceNumber: '',
      creditNote: [],
      downloadUrl: '',
    },
    {
      invoiceId: '462010002102',
      status: 'Paid',
      amount: '980.75 EUR',
      billingAddress: 'Rue de la Loi, Brussels',
      dueDate: '2024-08-30T08:00:00Z',
      referenceNumber: '65584930',
      plannedPaymentDate: '2024-08-29T08:00:00Z',
      issueDate: '2024-08-20T08:00:00Z',
      contactPerson: 'Lucien Martin',
      company: 'InnoTech',
      originalInvoiceNumber: '',
      creditNote: [],
      downloadUrl: '',
    },
    {
      invoiceId: '462010002103',
      status: 'Cancelled',
      amount: '725.00 GBP',
      billingAddress: '',
      dueDate: '2024-08-25T08:00:00Z',
      referenceNumber: '48756213',
      plannedPaymentDate: '',
      issueDate: '2024-08-15T08:00:00Z',
      contactPerson: 'Rachel Green',
      company: 'Opentech',
      originalInvoiceNumber: '',
      creditNote: [
        {
          invoiceId: '462010002104',
          status: 'Credit note',
          amount: '-725.00 GBP',
          issueDate: '2024-08-18T08:00:00Z',
          company: 'Opentech',
          originalInvoiceNumber: '462010002103',
          downloadUrl: '',
        },
      ],
      downloadUrl: '',
    },
    {
      invoiceId: '462010002105',
      status: 'Not paid',
      amount: '4750.00 USD',
      billingAddress: 'Wall Street, New York',
      dueDate: '2024-09-10T08:00:00Z',
      referenceNumber: '12874092',
      plannedPaymentDate: '2024-09-09T08:00:00Z',
      issueDate: '2024-08-30T08:00:00Z',
      contactPerson: 'John Doe',
      company: 'FinServe',
      originalInvoiceNumber: '',
      creditNote: [],
      downloadUrl: '',
    },
    {
      invoiceId: '462010002106',
      status: 'Paid',
      amount: '3125.50 NOK',
      billingAddress: 'Karl Johans gate, Oslo',
      dueDate: '2024-09-15T08:00:00Z',
      referenceNumber: '67584920',
      plannedPaymentDate: '2024-09-14T08:00:00Z',
      issueDate: '2024-08-29T08:00:00Z',
      contactPerson: 'Sigrid Hansen',
      company: 'Nordic IT',
      originalInvoiceNumber: '',
      creditNote: [],
      downloadUrl: '',
    },
    {
      invoiceId: '462010002107',
      status: 'Overdue',
      amount: '1899.99 EUR',
      billingAddress: 'Alexanderplatz, Berlin',
      dueDate: '2024-08-27T08:00:00Z',
      referenceNumber: '98675412',
      plannedPaymentDate: '2024-08-26T08:00:00Z',
      issueDate: '2024-08-17T08:00:00Z',
      contactPerson: 'Hans Müller',
      company: 'TechWave',
      originalInvoiceNumber: '',
      creditNote: [],
      downloadUrl: '',
    },
    {
      invoiceId: '462010002108',
      status: 'Cancelled',
      amount: '2400.00 USD',
      billingAddress: 'Broadway, New York',
      dueDate: '2024-09-05T08:00:00Z',
      referenceNumber: '46375920',
      plannedPaymentDate: '',
      issueDate: '2024-08-20T08:00:00Z',
      contactPerson: 'Emily Stone',
      company: 'CreativeWorks',
      originalInvoiceNumber: '',
      creditNote: [
        {
          invoiceId: '462010002109',
          status: 'Credit note',
          amount: '-2400.00 USD',
          issueDate: '2024-08-22T08:00:00Z',
          company: 'CreativeWorks',
          originalInvoiceNumber: '462010002108',
          downloadUrl: '',
        },
      ],
      downloadUrl: '',
    },
    {
      invoiceId: 'ZSEAF3502865',
      status: 'Paid',
      amount: '2400.00 USD',
      billingAddress:
        'Mott MacDonald House, 8-10 Sydenham Road, Croydon, CR0 2EE, United Kingdom',
      dueDate: '2019-06-20T00:00:00Z',
      referenceNumber: '78529129',
      plannedPaymentDate: '2020-08-16T00:00:00Z',
      issueDate: '2019-05-21T00:00:00Z',
      contactPerson: 'Emily Stone',
      company: 'TechWave',
      originalInvoiceNumber: '',
      creditNote: [],
      downloadUrl: '',
    },
    {
      invoiceId: 'ZSEAF213121012',
      status: 'Paid',
      amount: '3200.50 USD',
      billingAddress: 'Park Avenue, New York',
      dueDate: '2024-10-01T08:00:00Z',
      referenceNumber: '10584921',
      plannedPaymentDate: '2024-09-30T08:00:00Z',
      issueDate: '2024-09-15T08:00:00Z',
      contactPerson: 'Jake Sullivan',
      company: 'Tech Innovators',
      originalInvoiceNumber: '',
      creditNote: [],
      downloadUrl: '',
    },
    {
      invoiceId: 'Z-NO-G61584',
      status: 'Not paid',
      amount: '2750.00 EUR',
      billingAddress: 'Gotham Street, Oslo',
      dueDate: '2024-09-15T08:00:00Z',
      referenceNumber: '11029483',
      plannedPaymentDate: '2024-09-14T08:00:00Z',
      issueDate: '2024-09-01T08:00:00Z',
      contactPerson: 'Sigrid Larsen',
      company: 'Nordic Ventures',
      originalInvoiceNumber: '',
      creditNote: [],
      downloadUrl: '',
    },
    {
      invoiceId: 'Z-NO-G61817',
      status: 'Overdue',
      amount: '1900.99 EUR',
      billingAddress: 'Karl Johans gate, Oslo',
      dueDate: '2024-08-25T08:00:00Z',
      referenceNumber: '12038495',
      plannedPaymentDate: '2024-08-24T08:00:00Z',
      issueDate: '2024-08-10T08:00:00Z',
      contactPerson: 'Olav Jensen',
      company: 'OsloTech',
      originalInvoiceNumber: '',
      creditNote: [],
      downloadUrl: '',
    },
    {
      invoiceId: 'ZCEPL7501701242',
      status: 'Cancelled',
      amount: '5100.00 PLN',
      billingAddress: 'Nowy Świat, Warsaw',
      dueDate: '2024-07-20T08:00:00Z',
      referenceNumber: '98530147',
      plannedPaymentDate: '',
      issueDate: '2024-07-05T08:00:00Z',
      contactPerson: 'Zofia Kowalska',
      company: 'Polish Tech',
      originalInvoiceNumber: '',
      creditNote: [
        {
          invoiceId: 'ZCEPL7501701243',
          status: 'Credit note',
          amount: '-5100.00 PLN',
          issueDate: '2024-07-18T08:00:00Z',
          company: 'Polish Tech',
          originalInvoiceNumber: 'ZCEPL7501701242',
          downloadUrl: '',
        },
      ],
      downloadUrl: '',
    },
    {
      invoiceId: 'ZSEAF3502917',
      status: 'Paid',
      amount: '7500.00 USD',
      billingAddress: 'Beverly Hills, Los Angeles',
      dueDate: '2024-06-15T08:00:00Z',
      referenceNumber: '76521904',
      plannedPaymentDate: '2024-06-14T08:00:00Z',
      issueDate: '2024-05-30T08:00:00Z',
      contactPerson: 'Elena Martinez',
      company: 'West Coast Innovations',
      originalInvoiceNumber: '',
      creditNote: [],
      downloadUrl: '',
    },
    {
      invoiceId: 'ZSEAF213121285',
      status: 'Not paid',
      amount: '6800.00 USD',
      billingAddress: 'Silicon Valley, California',
      dueDate: '2024-11-01T08:00:00Z',
      referenceNumber: '11089201',
      plannedPaymentDate: '2024-10-31T08:00:00Z',
      issueDate: '2024-10-15T08:00:00Z',
      contactPerson: 'Kevin O’Brien',
      company: 'Future Tech',
      originalInvoiceNumber: '',
      creditNote: [],
      downloadUrl: '',
    },
  ],
  isSuccess: true,
  errorCode: '',
  message: '',
};
