<div
  class="active-filter-tags"
  [ngClass]="{
    'active-filter-tags--empty': !activeFilters().length,
  }">
  @for (tag of activeFilters(); track tag.label) {
    <shared-active-filter-tag
      [tag]="tag"
      (deleteSavedFilter)="
        handleFilterPreferenceAction($event, FilterPreferenceAction.Delete)
      "
      (removeFilter)="onRemoveFilter($event)"
      (saveFilter)="
        handleFilterPreferenceAction($event, FilterPreferenceAction.Save)
      "></shared-active-filter-tag>
  }
</div>
