import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
} from '@angular/core';
import tippy, { Instance } from 'tippy.js';

import { TooltipPlacements, TooltipThemes } from '../../constants';

@Directive({
  selector: '[sharedTippyTooltip]',
  standalone: true,
})
export class TippyTooltipDirective implements AfterViewInit, OnDestroy {
  private tooltipInstance: Instance | null = null;
  private resizeObserver: ResizeObserver;
  @Input('sharedTippyTooltip') content?: string;
  @Input() tooltipPlacement: TooltipPlacements = TooltipPlacements.Bottom;
  @Input() displayAllways = false;
  @Input() tooltipTheme: TooltipThemes = TooltipThemes.Dark;

  constructor(private el: ElementRef) {
    this.resizeObserver = new ResizeObserver(() => {
      this.checkOverflow();
    });
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(): void {
    this.checkOverflow();
  }

  ngAfterViewInit(): void {
    this.createTooltip();
    this.checkOverflow();
    this.resizeObserver.observe(this.el.nativeElement);
  }

  ngOnDestroy(): void {
    this.resizeObserver.unobserve(this.el.nativeElement);
  }

  private createTooltip(): void {
    const element = this.el.nativeElement;

    this.tooltipInstance = tippy(element, {
      content: this.content || element.textContent,
      delay: [100, 0],
      theme: this.tooltipTheme,
      arrow: true,
      placement: this.tooltipPlacement,
    }) as any;
  }

  private checkOverflow(): void {
    if (!this.displayAllways) {
      const element = this.el.nativeElement;

      if (element.scrollWidth > element.clientWidth) {
        this.tooltipInstance?.enable();
      } else {
        this.tooltipInstance?.disable();
      }
    }
  }
}
