var l71 = {
  code: 'tr',
  week: {
    dow: 1,
    doy: 7 // The week that contains Jan 1st is the first week of the year.
  },
  buttonText: {
    prev: 'geri',
    next: 'ileri',
    today: 'bugün',
    year: 'Yıl',
    month: 'Ay',
    week: 'Hafta',
    day: 'Gün',
    list: 'Ajanda'
  },
  weekText: 'Hf',
  allDayText: 'Tüm gün',
  moreLinkText: 'daha fazla',
  noEventsText: 'Gösterilecek etkinlik yok'
};
export { l71 as default };