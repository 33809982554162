var l25 = {
  code: 'et',
  week: {
    dow: 1,
    doy: 4 // The week that contains Jan 4th is the first week of the year.
  },
  buttonText: {
    prev: 'Eelnev',
    next: 'Järgnev',
    today: 'Täna',
    year: 'Aasta',
    month: 'Kuu',
    week: 'Nädal',
    day: 'Päev',
    list: 'Päevakord'
  },
  weekText: 'näd',
  allDayText: 'Kogu päev',
  moreLinkText(n) {
    return '+ veel ' + n;
  },
  noEventsText: 'Kuvamiseks puuduvad sündmused'
};
export { l25 as default };