<ng-container *transloco="let t">
  <div class="tree-table-container">
    @if (data && data.length) {
      <p-treeTable [columns]="cols" [scrollable]="true" [value]="data">
        <ng-template let-columns pTemplate="header">
          <tr>
            @for (col of columns; track col.field) {
              <th [style.width]="col.width || 'inherit'">
                {{ col.isTranslatable ? t(col.header) : col.header }}
              </th>
            }
          </tr>
        </ng-template>
        <ng-template
          let-columns="columns"
          let-rowData="rowData"
          let-rowNode
          pTemplate="body">
          <tr [ttRow]="rowNode">
            @for (col of columns; let i = $index; track col.field) {
              <td [style.width]="col.width || 'inherit'">
                @if (i === 0) {
                  <p-treeTableToggler
                    [ngClass]="{
                      'parent-row':
                        rowNode.node.children &&
                        rowNode.node.children.length > 0,
                    }"
                    [rowNode]="rowNode" />
                }
                <span
                  [class]="categories.get(rowData[col.field]) || ''"
                  [ngClass]="{
                    'parent-cell':
                      rowNode.node.children && rowNode.node.children.length > 0,
                  }">
                  {{ rowData[col.field] }}
                </span>
              </td>
            }
          </tr>
        </ng-template>
      </p-treeTable>
    }
  </div>
</ng-container>
