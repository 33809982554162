var l34 = {
  code: 'hi',
  week: {
    dow: 0,
    doy: 6 // The week that contains Jan 1st is the first week of the year.
  },
  buttonText: {
    prev: 'पिछला',
    next: 'अगला',
    today: 'आज',
    year: 'वर्ष',
    month: 'महीना',
    week: 'सप्ताह',
    day: 'दिन',
    list: 'कार्यसूची'
  },
  weekText: 'हफ्ता',
  allDayText: 'सभी दिन',
  moreLinkText(n) {
    return '+अधिक ' + n;
  },
  noEventsText: 'कोई घटनाओं को प्रदर्शित करने के लिए'
};
export { l34 as default };