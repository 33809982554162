import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@customer-portal/environments';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly authApiUrl = environment.authApiUrl;

  constructor(private readonly http: HttpClient) {}

  login(): void {
    window.location.href = `${this.authApiUrl}/login?returnUrl=${encodeURIComponent(environment.baseUrl)}`;
  }

  logout(): Observable<string> {
    return this.http.post<string>(
      `${this.authApiUrl}/Logout`,
      {},
      { responseType: 'text' as 'json', withCredentials: true },
    );
  }

  getToken(): Observable<string> {
    return this.http.get(`${this.authApiUrl}/GetAccessToken`, {
      responseType: 'text',
      withCredentials: true,
    });
  }

  getClientCredentialToken(): Observable<string> {
    return this.http.get<string>(`${this.authApiUrl}/Status`);
  }

  isUserAuthenticated(): Observable<boolean> {
    return this.http.get<boolean>(`${this.authApiUrl}/IsUserAuthenticated`, {
      withCredentials: true,
    });
  }
}
