var l61 = {
  code: 'si-lk',
  week: {
    dow: 1,
    doy: 4 // The week that contains Jan 4th is the first week of the year.
  },
  buttonText: {
    prev: 'පෙර',
    next: 'පසු',
    today: 'අද',
    year: 'අවුරුදු',
    month: 'මාසය',
    week: 'සතිය',
    day: 'දවස',
    list: 'ලැයිස්තුව'
  },
  weekText: 'සති',
  allDayText: 'සියලු',
  moreLinkText: 'තවත්',
  noEventsText: 'මුකුත් නැත'
};
export { l61 as default };