import { MockedResponse } from '../model';

interface ContractListItemDto {
  contractId: string;
  contractName: string;
  contractType: string;
  company: string;
  services: ContractServiceDto[];
  site: string;
  dateAdded: string;
  downloadUrl: string;
}

export interface ContractServiceDto {
  id: number;
  name: string;
}

export const CONTRACT_LIST: MockedResponse<ContractListItemDto> = {
  data: [
    {
      contractId: '20',
      contractName: 'Contract 20',
      contractType: 'Contract',
      company: 'Opentech',
      services: [
        {
          id: 3,
          name: 'ISO IEC',
        },
        {
          id: 0,
          name: 'ISO 9001:2015',
        },
      ],
      site: 'Opentech - Asia Pacific Group',
      dateAdded: '2024-12-15T08:00:00Z',
      downloadUrl: '',
    },
    {
      contractId: '18',
      contractName: 'Contract 9',
      contractType: 'Contract',
      company: 'Opentech',
      services: [
        {
          id: 21,
          name: 'ISO 14001:2015',
        },
      ],
      site: 'Opentech (NZ) Ltd - Bowden',
      dateAdded: '2024-10-09T08:00:00Z',
      downloadUrl: '',
    },
    {
      contractId: '26',
      contractName: 'Contract 17',
      contractType: 'Contract',
      company: '',
      services: [
        {
          id: 0,
          name: 'ISO 9001:2015',
        },
        {
          id: 1,
          name: 'ISO 14001:2015',
        },
      ],
      site: '',
      dateAdded: '2024-09-15T08:00:00Z',
      downloadUrl: '',
    },
    {
      contractId: '17',
      contractName: 'Contract 8',
      contractType: 'Amendment',
      company: 'Opentech',
      services: [
        {
          id: 20,
          name: 'ISO 45001:2018',
        },
      ],
      site: 'Opentech - Asia Pacific Group',
      dateAdded: '',
      downloadUrl: '',
    },
    {
      contractId: '0',
      contractName: 'Q281488',
      contractType: 'Contract',
      company: 'Mott MacDonald Limited',
      services: [],
      site: 'Mott MacDonald Australia Pty Limited (Adelaide)',
      dateAdded: '2024-09-01T08:00:00Z',
      downloadUrl: '',
    },
    {
      contractId: '1',
      contractName: 'Contract 1',
      contractType: 'Contract',
      company: 'Mott MacDonald Limited',
      services: [
        {
          id: 1,
          name: 'ISO 37001:2016',
        },
      ],
      site: 'Mott MacDonald Private Limited (Bangalore)',
      dateAdded: '2024-09-01T08:00:00Z',
      downloadUrl: '',
    },
    {
      contractId: '2',
      contractName: 'Q256474',
      contractType: 'Contract',
      company: 'Mott MacDonald Limited',
      services: [
        {
          id: 2,
          name: 'ISO 14001:2015',
        },
      ],
      site: 'Mott MacDonald Limited (Liverpool)',
      dateAdded: '2024-09-01T08:00:00Z',
      downloadUrl: '',
    },
    {
      contractId: '3',
      contractName: 'Q193428',
      contractType: 'Amendment',
      company: 'Mott MacDonald Limited',
      services: [
        {
          id: 0,
          name: 'ISO 19443:2018',
        },
      ],
      site: 'Mott MacDonald Private Limited (Andheri-East)',
      dateAdded: '2024-09-01T08:00:00Z',
      downloadUrl: '',
    },
    {
      contractId: '4',
      contractName: 'Q178089',
      contractType: 'Contract',
      company: 'Mott MacDonald Limited',
      services: [
        {
          id: 3,
          name: 'ISO 29001:2020',
        },
      ],
      site: 'Mott MacDonald (Beijing) Limited (Beijing)',
      dateAdded: '2024-09-01T08:00:00Z',
      downloadUrl: '',
    },
    {
      contractId: '5',
      contractName: 'Q73963',
      contractType: 'Amendment',
      company: 'Mott MacDonald Limited',
      services: [
        {
          id: 4,
          name: 'ISO 14001:2015',
        },
      ],
      site: 'Mott MacDonald BV (Utrecht)',
      dateAdded: '2024-09-01T08:00:00Z',
      downloadUrl: '',
    },
    {
      contractId: '6',
      contractName: 'Q35301',
      contractType: 'Amendment',
      company: 'Mott MacDonald Limited',
      services: [
        {
          id: 5,
          name: 'ISO 9001:2015',
        },
      ],
      site: 'Mott MacDonald CZ, spol. s r.o. (Brno)',
      dateAdded: '2024-09-01T08:00:00Z',
      downloadUrl: '',
    },
    {
      contractId: '7',
      contractName: 'NB - Additional Training day',
      contractType: 'Contract',
      company: 'Mott MacDonald Limited',
      services: [
        {
          id: 6,
          name: 'Training',
        },
      ],
      site: 'Mott MacDonald Limited',
      dateAdded: '2024-09-01T08:00:00Z',
      downloadUrl: '',
    },
    {
      contractId: '8',
      contractName: 'NB - 27001 Training Europe',
      contractType: 'Amendment',
      company: 'Mott MacDonald Limited',
      services: [
        {
          id: 7,
          name: 'Training',
        },
      ],
      site: 'Mott MacDonald Limited',
      dateAdded: '2024-09-01T08:00:00Z',
      downloadUrl: '',
    },
    {
      contractId: '9',
      contractName: 'NB - In-house Training 27001',
      contractType: 'Amendment',
      company: 'Mott MacDonald Limited',
      services: [
        {
          id: 8,
          name: 'Contract',
        },
      ],
      site: 'Mott MacDonald Limited',
      dateAdded: '2024-09-01T08:00:00Z',
      downloadUrl: '',
    },
    {
      contractId: '10',
      contractName: 'Contract 1',
      contractType: 'Contract',
      company: 'Opentech',
      services: [
        {
          id: 9,
          name: 'ISO 9001:2015',
        },
        {
          id: 10,
          name: 'ISO 14001:2015',
        },
      ],
      site: 'Opentech - Asia Pacific Group',
      dateAdded: '2024-09-01T08:00:00Z',
      downloadUrl: '',
    },
    {
      contractId: '16',
      contractName: 'Contract 7',
      contractType: 'Contract',
      company: 'Opentech',
      services: [
        {
          id: 18,
          name: 'ISO 45001:2018',
        },
        {
          id: 19,
          name: 'ISO IEC',
        },
      ],
      site: 'Opentech (Castle Hill)',
      dateAdded: '2024-08-09T08:00:00Z',
      downloadUrl: '',
    },
    {
      contractId: '21',
      contractName: 'Contract 12',
      contractType: 'Amendment',
      company: 'Opentech',
      services: [
        {
          id: 1,
          name: 'ISO 14001:2015',
        },
      ],
      site: 'Opentech (NZ) Ltd - Bowden',
      dateAdded: '2024-07-18T08:00:00Z',
      downloadUrl: '',
    },
    {
      contractId: '25',
      contractName: 'Contract 16',
      contractType: 'Amendment',
      company: 'Opentech',
      services: [
        {
          id: 2,
          name: 'ISO 45001:2018',
        },
      ],
      site: 'Opentech - Asia Pacific Group',
      dateAdded: '2024-06-20T08:00:00Z',
      downloadUrl: '',
    },
    {
      contractId: '15',
      contractName: 'Contract 6',
      contractType: 'Contract',
      company: 'Opentech',
      services: [
        {
          id: 17,
          name: 'ISO 14001:2015',
        },
      ],
      site: 'Opentech (Castle Hill)',
      dateAdded: '2024-06-09T08:00:00Z',
      downloadUrl: '',
    },
    {
      contractId: '24',
      contractName: 'Contract 15',
      contractType: 'Contract',
      company: 'Opentech',
      services: [
        {
          id: 1,
          name: 'ISO 14001:2015',
        },
        {
          id: 3,
          name: 'ISO IEC',
        },
      ],
      site: 'Opentech (NZ) Ltd - Bowden',
      dateAdded: '2024-05-05T08:00:00Z',
      downloadUrl: '',
    },
    {
      contractId: '23',
      contractName: 'Contract 14',
      contractType: 'Amendment',
      company: 'Opentech',
      services: [
        {
          id: 0,
          name: 'ISO 9001:2015',
        },
      ],
      site: 'Opentech - Asia Pacific Group',
      dateAdded: '2024-04-10T08:00:00Z',
      downloadUrl: '',
    },
    {
      contractId: '19',
      contractName: 'Contract 10',
      contractType: 'Contract',
      company: 'Opentech',
      services: [
        {
          id: 2,
          name: 'ISO 45001:2018',
        },
      ],
      site: 'Opentech (Castle Hill)',
      dateAdded: '2024-03-09T08:00:00Z',
      downloadUrl: '',
    },
    {
      contractId: '14',
      contractName: 'Contract 5',
      contractType: 'Amendment',
      company: 'Opentech',
      services: [
        {
          id: 15,
          name: 'ISO 9001:2015',
        },
        {
          id: 16,
          name: 'ISO 14001:2015',
        },
      ],
      site: 'Opentech - Asia Pacific Group',
      dateAdded: '2024-02-09T08:00:00Z',
      downloadUrl: '',
    },
    {
      contractId: '22',
      contractName: 'Contract 13',
      contractType: 'Contract',
      company: 'Opentech',
      services: [
        {
          id: 2,
          name: 'ISO 45001:2018',
        },
      ],
      site: 'Opentech (Castle Hill)',
      dateAdded: '2024-01-12T08:00:00Z',
      downloadUrl: '',
    },
    {
      contractId: '11',
      contractName: 'Contract 2',
      contractType: 'Amendment',
      company: 'Opentech',
      services: [
        {
          id: 11,
          name: 'ISO 14001:2015',
        },
      ],
      site: 'Opentech (Castle Hill)',
      dateAdded: '2023-09-02T08:00:00Z',
      downloadUrl: '',
    },
    {
      contractId: '12',
      contractName: 'Contract 3',
      contractType: 'Contract',
      company: 'Opentech',
      services: [
        {
          id: 12,
          name: 'ISO 45001:2018',
        },
        {
          id: 13,
          name: 'ISO IEC',
        },
      ],
      site: 'Opentech (NZ) Ltd - Bowden',
      dateAdded: '2023-08-02T08:00:00Z',
      downloadUrl: '',
    },
    {
      contractId: '13',
      contractName: '',
      contractType: 'Contract',
      company: 'Opentech',
      services: [
        {
          id: 14,
          name: 'ISO 45001:2018',
        },
      ],
      site: 'Opentech - Asia Pacific Group',
      dateAdded: '2023-05-09T08:00:00Z',
      downloadUrl: '',
    },
  ],
  isSuccess: true,
  errorCode: '',
  message: '',
};
