var l33 = {
  code: 'he',
  direction: 'rtl',
  buttonText: {
    prev: 'הקודם',
    next: 'הבא',
    today: 'היום',
    year: 'שנה',
    month: 'חודש',
    week: 'שבוע',
    day: 'יום',
    list: 'סדר יום'
  },
  allDayText: 'כל היום',
  moreLinkText: 'נוספים',
  noEventsText: 'אין אירועים להצגה',
  weekText: 'שבוע'
};
export { l33 as default };