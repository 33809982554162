var l9 = {
  code: 'bg',
  week: {
    dow: 1,
    doy: 4 // The week that contains Jan 4th is the first week of the year.
  },
  buttonText: {
    prev: 'назад',
    next: 'напред',
    today: 'днес',
    year: 'година',
    month: 'Месец',
    week: 'Седмица',
    day: 'Ден',
    list: 'График'
  },
  allDayText: 'Цял ден',
  moreLinkText(n) {
    return '+още ' + n;
  },
  noEventsText: 'Няма събития за показване'
};
export { l9 as default };