var l48 = {
  code: 'lt',
  week: {
    dow: 1,
    doy: 4 // The week that contains Jan 4th is the first week of the year.
  },
  buttonText: {
    prev: 'Atgal',
    next: 'Pirmyn',
    today: 'Šiandien',
    year: 'Metai',
    month: 'Mėnuo',
    week: 'Savaitė',
    day: 'Diena',
    list: 'Darbotvarkė'
  },
  weekText: 'SAV',
  allDayText: 'Visą dieną',
  moreLinkText: 'daugiau',
  noEventsText: 'Nėra įvykių rodyti'
};
export { l48 as default };