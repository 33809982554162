var l12 = {
  code: 'ca',
  week: {
    dow: 1,
    doy: 4 // The week that contains Jan 4th is the first week of the year.
  },
  buttonText: {
    prev: 'Anterior',
    next: 'Següent',
    today: 'Avui',
    year: 'Any',
    month: 'Mes',
    week: 'Setmana',
    day: 'Dia',
    list: 'Agenda'
  },
  weekText: 'Set',
  allDayText: 'Tot el dia',
  moreLinkText: 'més',
  noEventsText: 'No hi ha esdeveniments per mostrar'
};
export { l12 as default };