<div class="container-icon">
  @if (displayIcon && iconPosition === 'prefix') {
    <i
      tooltipPosition="top"
      class="{{ iconClass }}"
      [pTooltip]="tooltipMessage">
    </i>
  }
</div>

<span>{{ text }}</span>

<div class="container-icon">
  @if (displayIcon && iconPosition === 'suffix') {
    <i
      tooltipPosition="top"
      class="{{ iconClass }}"
      [pTooltip]="tooltipMessage">
    </i>
  }
</div>