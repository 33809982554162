var l14 = {
  code: 'cy',
  week: {
    dow: 1,
    doy: 4 // The week that contains Jan 4th is the first week of the year.
  },
  buttonText: {
    prev: 'Blaenorol',
    next: 'Nesaf',
    today: 'Heddiw',
    year: 'Blwyddyn',
    month: 'Mis',
    week: 'Wythnos',
    day: 'Dydd',
    list: 'Rhestr'
  },
  weekText: 'Wythnos',
  allDayText: 'Trwy\'r dydd',
  moreLinkText: 'Mwy',
  noEventsText: 'Dim digwyddiadau'
};
export { l14 as default };