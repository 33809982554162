var l72 = {
  code: 'ug',
  buttonText: {
    prev: 'ئالدىنقى',
    next: 'كېيىنكى',
    today: 'بۈگۈن',
    year: 'يىل',
    month: 'ئاي',
    week: 'ھەپتە',
    day: 'كۈن',
    list: 'كۈنتەرتىپ'
  },
  allDayText: 'پۈتۈن كۈن'
};
export { l72 as default };