var l45 = {
  code: 'ko',
  buttonText: {
    prev: '이전달',
    next: '다음달',
    today: '오늘',
    year: '년도',
    month: '월',
    week: '주',
    day: '일',
    list: '일정목록'
  },
  weekText: '주',
  allDayText: '종일',
  moreLinkText: '개',
  noEventsText: '일정이 없습니다'
};
export { l45 as default };