import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  output,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoDirective } from '@jsverse/transloco';
import { TreeNode } from 'primeng/api';
import { TreeNodeSelectEvent } from 'primeng/tree';
import { TreeSelectModule } from 'primeng/treeselect';

import {
  SHARED_SELECT_TREE_SCROLL_HEIGHT_PX,
  SHARED_SELECT_TREE_SEARCH_LIMIT,
} from './select-tree.constants';

export interface SharedSelectTreeChangeEventOutput {
  filter: number[];
  prefill: TreeNode[];
}

@Component({
  selector: 'shared-select-tree',
  standalone: true,
  imports: [CommonModule, TranslocoDirective, TreeSelectModule, FormsModule],
  templateUrl: './select-tree.component.html',
  styleUrl: './select-tree.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedSelectTreeComponent {
  public options = input.required<TreeNode[]>();
  public placeholder = input<string>();
  public prefill = input<TreeNode[]>([]);

  public changeEvent = output<SharedSelectTreeChangeEventOutput>();

  public hasSearch = computed(
    () => this.options().length > SHARED_SELECT_TREE_SEARCH_LIMIT,
  );
  public scrollHeight = `${SHARED_SELECT_TREE_SCROLL_HEIGHT_PX}px`;
  public selected: TreeNode[] = [];

  onChange(_event: TreeNodeSelectEvent): void {
    this.changeEvent.emit({
      filter: Array.from(new Set(this.selected.map((s) => Number(s.data)))),
      prefill: this.selected,
    });
  }
}
