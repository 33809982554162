var l38 = {
  code: 'id',
  week: {
    dow: 1,
    doy: 7 // The week that contains Jan 1st is the first week of the year.
  },
  buttonText: {
    prev: 'mundur',
    next: 'maju',
    today: 'hari ini',
    year: 'Tahun',
    month: 'Bulan',
    week: 'Minggu',
    day: 'Hari',
    list: 'Agenda'
  },
  weekText: 'Mg',
  allDayText: 'Sehari penuh',
  moreLinkText: 'lebih',
  noEventsText: 'Tidak ada acara untuk ditampilkan'
};
export { l38 as default };