var l47 = {
  code: 'lb',
  week: {
    dow: 1,
    doy: 4 // The week that contains Jan 4th is the first week of the year.
  },
  buttonText: {
    prev: 'Zréck',
    next: 'Weider',
    today: 'Haut',
    year: 'Joer',
    month: 'Mount',
    week: 'Woch',
    day: 'Dag',
    list: 'Terminiwwersiicht'
  },
  weekText: 'W',
  allDayText: 'Ganzen Dag',
  moreLinkText: 'méi',
  noEventsText: 'Nee Evenementer ze affichéieren'
};
export { l47 as default };