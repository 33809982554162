var l65 = {
  code: 'sq',
  week: {
    dow: 1,
    doy: 4 // The week that contains Jan 4th is the first week of the year.
  },
  buttonText: {
    prev: 'mbrapa',
    next: 'Përpara',
    today: 'Sot',
    year: 'Viti',
    month: 'Muaj',
    week: 'Javë',
    day: 'Ditë',
    list: 'Listë'
  },
  weekText: 'Ja',
  allDayText: 'Gjithë ditën',
  moreLinkText(n) {
    return '+më tepër ' + n;
  },
  noEventsText: 'Nuk ka evente për të shfaqur'
};
export { l65 as default };