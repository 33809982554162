var l0 = {
  code: 'af',
  week: {
    dow: 1,
    doy: 4 // Die week wat die 4de Januarie bevat is die eerste week van die jaar.
  },
  buttonText: {
    prev: 'Vorige',
    next: 'Volgende',
    today: 'Vandag',
    year: 'Jaar',
    month: 'Maand',
    week: 'Week',
    day: 'Dag',
    list: 'Agenda'
  },
  allDayText: 'Heeldag',
  moreLinkText: 'Addisionele',
  noEventsText: 'Daar is geen gebeurtenisse nie'
};
export { l0 as default };