import saveAs from 'file-saver';

export const downloadFileFromByteArray = (
  inputArray: number[],
  fileName: string,
  mimeType: string,
) => {
  const uint8Array = new Uint8Array(inputArray);
  const blob = new Blob([uint8Array], {
    type: mimeType,
  });
  saveAs(blob, fileName);
};
