var l8 = {
  code: 'az',
  week: {
    dow: 1,
    doy: 4 // The week that contains Jan 4th is the first week of the year.
  },
  buttonText: {
    prev: 'Əvvəl',
    next: 'Sonra',
    today: 'Bu Gün',
    year: 'Il',
    month: 'Ay',
    week: 'Həftə',
    day: 'Gün',
    list: 'Gündəm'
  },
  weekText: 'Həftə',
  allDayText: 'Bütün Gün',
  moreLinkText(n) {
    return '+ daha çox ' + n;
  },
  noEventsText: 'Göstərmək üçün hadisə yoxdur'
};
export { l8 as default };