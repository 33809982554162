var l78 = {
  code: 'zh-tw',
  buttonText: {
    prev: '上個',
    next: '下個',
    today: '今天',
    year: '年',
    month: '月',
    week: '週',
    day: '天',
    list: '活動列表'
  },
  weekText: '週',
  allDayText: '整天',
  moreLinkText: '顯示更多',
  noEventsText: '沒有任何活動'
};
export { l78 as default };