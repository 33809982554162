var l50 = {
  code: 'mk',
  buttonText: {
    prev: 'претходно',
    next: 'следно',
    today: 'Денес',
    year: 'година',
    month: 'Месец',
    week: 'Недела',
    day: 'Ден',
    list: 'График'
  },
  weekText: 'Сед',
  allDayText: 'Цел ден',
  moreLinkText(n) {
    return '+повеќе ' + n;
  },
  noEventsText: 'Нема настани за прикажување'
};
export { l50 as default };