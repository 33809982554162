import { DateTime } from 'luxon';

/**
 * Converts a string in dd-mm-yyyy format to a Date object.
 * @param dateString The date string in dd-mm-yyyy format.
 * @returns The Date object corresponding to the input string.
 */
export const convertStringToDate = (dateString: string): Date => {
  const trimmedDateString = dateString.trim();
  const dateRegex = /^(\d{2})-(\d{2})-(\d{4})$/;

  if (!dateRegex.test(trimmedDateString)) {
    throw new Error(
      'Invalid date string format. Date string must be in dd-mm-yyyy format.',
    );
  }

  const [day, month, year] = trimmedDateString.split('-').map(Number);
  const date = new Date(year, month - 1, day);

  const [parsedDay, parsedMonth, parsedYear] = [
    date.getDate(),
    date.getMonth() + 1,
    date.getFullYear(),
  ];

  if (day !== parsedDay || month !== parsedMonth || year !== parsedYear) {
    throw new Error(
      'Invalid date. The resulting date does not match the input string.',
    );
  }

  return date;
};

export const convertToUtcDate = (
  inputDateTime?: string,
  format = 'dd-MM-yyyy',
): string => {
  if (!inputDateTime) {
    return '';
  }

  const dateTime = DateTime.fromISO(inputDateTime, { zone: 'utc' });

  return dateTime.toFormat(format);
};

export const utcDateToPayloadFormat = (inputDate: string): string => {
  const parsedDate = DateTime.fromFormat(inputDate, 'dd-MM-yyyy');

  return parsedDate.toFormat('yyyy-MM-dd');
};

export const extractTimeFromIsoDate = (inputDateTime?: string): string => {
  if (!inputDateTime) {
    return '';
  }

  const dateTime = DateTime.fromISO(inputDateTime, { zone: 'utc' });

  return dateTime.toFormat('HH:mm');
};

export const formatDateToGivenZoneAndFormat = (
  inputDateTime: string,
  zone: string,
  format = 'dd-MM-yyyy HH:mm',
): string => {
  if (!inputDateTime) {
    return '';
  }

  const localDateTime = DateTime.fromISO(inputDateTime).setZone(zone);

  return localDateTime.toFormat(format);
};

export const formatDateToGivenZoneAndLocale = (
  utcDate: string,
  timeZone: string,
  locale: string,
): string =>
  DateTime.fromISO(utcDate)
    .setZone(timeZone)
    .setLocale(locale)
    .toLocaleString(DateTime.DATE_SHORT);

export const isDateInPast = (date: string): boolean =>
  new Date(date).getTime() < new Date(new Date()).getTime();

export const calculateWeekRange = (date: Date): [Date, Date] => {
  const inputDate = new Date(date);

  const dayOfWeek = inputDate.getDay();

  const diffToMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1;

  let startOfWeek = new Date(inputDate);
  startOfWeek.setDate(inputDate.getDate() - diffToMonday);

  const today = new Date();

  if (startOfWeek < today) {
    startOfWeek = today;
  }

  const endOfWeek = new Date(startOfWeek);
  endOfWeek.setDate(startOfWeek.getDate() + (7 - endOfWeek.getDay()));

  return [startOfWeek, endOfWeek];
};
