import l0 from './locales/af.js';
import l1 from './locales/ar-dz.js';
import l2 from './locales/ar-kw.js';
import l3 from './locales/ar-ly.js';
import l4 from './locales/ar-ma.js';
import l5 from './locales/ar-sa.js';
import l6 from './locales/ar-tn.js';
import l7 from './locales/ar.js';
import l8 from './locales/az.js';
import l9 from './locales/bg.js';
import l10 from './locales/bn.js';
import l11 from './locales/bs.js';
import l12 from './locales/ca.js';
import l13 from './locales/cs.js';
import l14 from './locales/cy.js';
import l15 from './locales/da.js';
import l16 from './locales/de-at.js';
import l17 from './locales/de.js';
import l18 from './locales/el.js';
import l19 from './locales/en-au.js';
import l20 from './locales/en-gb.js';
import l21 from './locales/en-nz.js';
import l22 from './locales/eo.js';
import l23 from './locales/es-us.js';
import l24 from './locales/es.js';
import l25 from './locales/et.js';
import l26 from './locales/eu.js';
import l27 from './locales/fa.js';
import l28 from './locales/fi.js';
import l29 from './locales/fr-ca.js';
import l30 from './locales/fr-ch.js';
import l31 from './locales/fr.js';
import l32 from './locales/gl.js';
import l33 from './locales/he.js';
import l34 from './locales/hi.js';
import l35 from './locales/hr.js';
import l36 from './locales/hu.js';
import l37 from './locales/hy-am.js';
import l38 from './locales/id.js';
import l39 from './locales/is.js';
import l40 from './locales/it.js';
import l41 from './locales/ja.js';
import l42 from './locales/ka.js';
import l43 from './locales/kk.js';
import l44 from './locales/km.js';
import l45 from './locales/ko.js';
import l46 from './locales/ku.js';
import l47 from './locales/lb.js';
import l48 from './locales/lt.js';
import l49 from './locales/lv.js';
import l50 from './locales/mk.js';
import l51 from './locales/ms.js';
import l52 from './locales/nb.js';
import l53 from './locales/ne.js';
import l54 from './locales/nl.js';
import l55 from './locales/nn.js';
import l56 from './locales/pl.js';
import l57 from './locales/pt-br.js';
import l58 from './locales/pt.js';
import l59 from './locales/ro.js';
import l60 from './locales/ru.js';
import l61 from './locales/si-lk.js';
import l62 from './locales/sk.js';
import l63 from './locales/sl.js';
import l64 from './locales/sm.js';
import l65 from './locales/sq.js';
import l66 from './locales/sr-cyrl.js';
import l67 from './locales/sr.js';
import l68 from './locales/sv.js';
import l69 from './locales/ta-in.js';
import l70 from './locales/th.js';
import l71 from './locales/tr.js';
import l72 from './locales/ug.js';
import l73 from './locales/uk.js';
import l74 from './locales/uz-cy.js';
import l75 from './locales/uz.js';
import l76 from './locales/vi.js';
import l77 from './locales/zh-cn.js';
import l78 from './locales/zh-tw.js';
var localesAll = [l0, l1, l2, l3, l4, l5, l6, l7, l8, l9, l10, l11, l12, l13, l14, l15, l16, l17, l18, l19, l20, l21, l22, l23, l24, l25, l26, l27, l28, l29, l30, l31, l32, l33, l34, l35, l36, l37, l38, l39, l40, l41, l42, l43, l44, l45, l46, l47, l48, l49, l50, l51, l52, l53, l54, l55, l56, l57, l58, l59, l60, l61, l62, l63, l64, l65, l66, l67, l68, l69, l70, l71, l72, l73, l74, l75, l76, l77, l78];
export { localesAll as default };