import { MockedResponse } from '../model';

interface ScheduleListItemDto {
  scheduleId: number;
  startDate: string;
  endDate: string;
  status: string;
  services: ScheduleServiceDto[];
  site: string;
  address: string;
  city: string;
  auditType: string;
  leadAuditor: string;
  siteRepresentative: string;
  company: string;
}

interface ScheduleServiceDto {
  id: number;
  name: string;
}

export const SCHEDULES_LIST: MockedResponse<ScheduleListItemDto> = {
  data: [
    {
      scheduleId: 1,
      startDate: '2024-10-01T00:00:00.000+00:00',
      endDate: '2024-10-03T00:00:00.000+00:00',
      status: 'To Be Confirmed',
      services: [
        {
          id: 0,
          name: 'ISO 9001:2015',
        },
        {
          id: 1,
          name: 'ISO 14001:2015',
        },
      ],
      site: 'Enrich Solar Services Private Limited',
      city: 'Amsterdam',
      address: '123 Solar St, Amsterdam, Netherlands',
      auditType: 'Periodic Audit, P1',
      leadAuditor: 'Arne Arnesson',
      siteRepresentative: 'Jessie McGrath',
      company: 'Enrich Solar',
    },
    {
      scheduleId: 2,
      startDate: '2024-07-05T00:00:00.000+00:00',
      endDate: '2024-07-09T00:00:00.000+00:00',
      status: 'Confirmed',
      services: [
        {
          id: 10,
          name: 'ISO 540001:2018',
        },
      ],
      site: 'Opentech (NZ)',
      address: '456 Tech Rd, Auckland, New Zealand',
      city: 'Auckland',
      auditType: 'Initial Audit',
      leadAuditor: 'Arne Arnesson',
      siteRepresentative: 'Jessie McGrath',
      company: 'Opentech',
    },
    {
      scheduleId: 4,
      startDate: '2024-06-12T00:00:00.000+00:00',
      endDate: '2024-06-14T00:00:00.000+00:00',
      status: 'Cancelled',
      services: [
        {
          id: 1,
          name: 'ISO 14001:2015',
        },
      ],
      site: 'Enrich Solar Services Private Limited',
      address: '123 Solar St, Amsterdam, Netherlands',
      city: 'Amsterdam',
      auditType: 'Surveillance Audit',
      leadAuditor: 'Jane Smith',
      siteRepresentative: 'Sam Williams',
      company: 'Enrich Solar',
    },
    {
      scheduleId: 5,
      startDate: '2024-06-16T00:00:00.000+00:00',
      endDate: '2024-06-18T00:00:00.000+00:00',
      status: 'Confirmed',
      services: [
        {
          id: 11,
          name: 'ISO 22000:2018',
        },
      ],
      site: 'Opentech (Canada)',
      address: '789 Maple Ave, Toronto, Canada',
      city: 'Toronto',
      auditType: 'Re-certification Audit',
      leadAuditor: 'Arne Arnesson',
      siteRepresentative: 'Jessie McGrath',
      company: 'Opentech',
    },
    {
      scheduleId: 6,
      startDate: '2024-09-19T00:00:00.000+00:00',
      endDate: '2024-09-21T00:00:00.000+00:00',
      status: 'To Be Confirmed',
      services: [
        {
          id: 2,
          name: 'ISO 45001:2018',
        },
        {
          id: 3,
          name: 'ISO IEC',
        },
      ],
      site: 'Enrich Energy Private Limited',
      address: '987 Energy Dr, Oslo, Norway',
      city: 'Oslo',
      auditType: 'Surveillance Audit',
      leadAuditor: 'John Doe',
      siteRepresentative: 'Sara Connor',
      company: 'Enrich Energy',
    },
    {
      scheduleId: 7,
      startDate: '2024-06-22T00:00:00.000+00:00',
      endDate: '2024-06-24T00:00:00.000+00:00',
      status: 'Confirmed',
      services: [
        {
          id: 12,
          name: 'ISO 27001:2013',
        },
      ],
      site: 'Opentech (Germany)',
      address: '321 Tech Str, Berlin, Germany',
      city: 'Berlin',
      auditType: 'Initial Audit',
      leadAuditor: 'Jane Smith',
      siteRepresentative: 'Sam Williams',
      company: 'Opentech',
    },
    {
      scheduleId: 8,
      startDate: '2024-06-25T00:00:00.000+00:00',
      endDate: '2024-06-27T00:00:00.000+00:00',
      status: 'To Be Confirmed by DNV',
      services: [
        {
          id: 0,
          name: 'ISO 9001:2015',
        },
        {
          id: 1,
          name: 'ISO 14001:2015',
        },
      ],
      site: 'Opentech (Japan)',
      address: '123 Tech Blvd, Tokyo, Japan',
      city: 'Tokyo',
      auditType: 'Periodic Audit, P1',
      leadAuditor: 'Arne Arnesson',
      siteRepresentative: 'Jessie McGrath',
      company: 'Opentech',
    },
    {
      scheduleId: 9,
      startDate: '2024-06-28T00:00:00.000+00:00',
      endDate: '2024-06-30T00:00:00.000+00:00',
      status: 'Confirmed',
      services: [
        {
          id: 0,
          name: 'ISO 9001:2015',
        },
        {
          id: 1,
          name: 'ISO 14001:2015',
        },
      ],
      site: 'Opentech (China)',
      address: '456 Innovation Rd, Shanghai, China',
      city: 'Shanghai',
      auditType: 'Follow-up Audit',
      leadAuditor: 'John Doe',
      siteRepresentative: '',
      company: 'Opentech',
    },
    {
      scheduleId: 10,
      startDate: '2024-07-01T00:00:00.000+00:00',
      endDate: '2024-07-03T00:00:00.000+00:00',
      status: 'Cancelled',
      services: [
        {
          id: 0,
          name: 'ISO 27001:2013',
        },
      ],
      site: 'Opentech (India)',
      address: '789 Gateway St, Mumbai, India',
      city: 'Mumbai',
      auditType: 'Surveillance Audit',
      leadAuditor: 'Jane Smith',
      siteRepresentative: 'Sam Williams',
      company: 'Opentech',
    },
    {
      scheduleId: 11,
      startDate: '2024-07-05T00:00:00.000+00:00',
      endDate: '2024-07-07T00:00:00.000+00:00',
      status: 'To Be Confirmed',
      services: [
        {
          id: 11,
          name: 'ISO 22000:2018',
        },
      ],
      site: 'Opentech (Brazil)',
      address: '456 Avenida Paulista, São Paulo, Brazil',
      city: 'São Paulo',
      auditType: 'Initial Audit',
      leadAuditor: 'Arne Arnesson',
      siteRepresentative: 'Jessie McGrath',
      company: 'Opentech',
    },
    {
      scheduleId: 12,
      startDate: '2024-07-08T00:00:00.000+00:00',
      endDate: '2024-07-10T00:00:00.000+00:00',
      status: 'Confirmed',
      services: [
        {
          id: 2,
          name: 'ISO 45001:2018',
        },
        {
          id: 3,
          name: 'ISO IEC',
        },
      ],
      site: 'Opentech (South Africa)',
      address: '123 Nelson Mandela St, Johannesburg, South Africa',
      city: 'Johannesburg',
      auditType: 'Periodic Audit, P1',
      leadAuditor: 'John Doe',
      siteRepresentative: 'Sara Connor',
      company: 'Opentech',
    },
    {
      scheduleId: 13,
      startDate: '2024-07-11T00:00:00.000+00:00',
      endDate: '2024-07-13T00:00:00.000+00:00',
      status: 'To Be Confirmed by DNV',
      services: [
        {
          id: 0,
          name: 'ISO 9001:2015',
        },
      ],
      site: 'Opentech (Russia)',
      address: '789 Red Square Ave, Moscow, Russia',
      city: 'Moscow',
      auditType: 'Surveillance Audit',
      leadAuditor: 'Jane Smith',
      siteRepresentative: 'Sam Williams',
      company: 'Opentech',
    },
    {
      scheduleId: 14,
      startDate: '2024-07-14T00:00:00.000+00:00',
      endDate: '2024-07-16T00:00:00.000+00:00',
      status: 'Confirmed',
      services: [
        {
          id: 12,
          name: 'ISO 27001:2013',
        },
      ],
      site: 'Opentech (Italy)',
      address: '456 Via Appia, Rome, Italy',
      city: 'Rome',
      auditType: 'Initial Audit',
      leadAuditor: 'Arne Arnesson',
      siteRepresentative: 'Jessie McGrath',
      company: 'Opentech',
    },
    {
      scheduleId: 15,
      startDate: '2024-07-17T00:00:00.000+00:00',
      endDate: '2024-07-19T00:00:00.000+00:00',
      status: 'To Be Confirmed',
      services: [
        {
          id: 1,
          name: 'ISO 14001:2015',
        },
      ],
      site: 'Opentech (Mexico)',
      address: '123 Paseo de la Reforma, Mexico City, Mexico',
      city: 'Mexico City',
      auditType: 'Follow-up Audit',
      leadAuditor: 'John Doe',
      siteRepresentative: 'Sara Connor',
      company: 'Opentech',
    },
    {
      scheduleId: 16,
      startDate: '2024-07-20T00:00:00.000+00:00',
      endDate: '2024-07-22T00:00:00.000+00:00',
      status: 'Cancelled',
      services: [
        {
          id: 0,
          name: 'ISO 9001:2015',
        },
      ],
      site: 'Opentech (Spain)',
      address: '123 Gran Via, Madrid, Spain',
      city: 'Madrid',
      auditType: 'Surveillance Audit',
      leadAuditor: 'Jane Smith',
      siteRepresentative: 'Sam Williams',
      company: 'Opentech',
    },
    {
      scheduleId: 17,
      startDate: '2024-07-05T00:00:00.000+00:00',
      endDate: '2024-07-20T00:00:00.000+00:00',
      status: 'To Be Confirmed by DNV',
      services: [
        {
          id: 0,
          name: 'ISO 9001:2015',
        },
      ],
      site: 'Opentech (Netherlands)',
      address: '456 Keizersgracht, Amsterdam, Netherlands',
      city: 'Amsterdam',
      auditType: 'Follow-up Audit',
      leadAuditor: 'John Doe',
      siteRepresentative: 'Sara Connor',
      company: 'Opentech',
    },
    {
      scheduleId: 18,
      startDate: '2024-07-05T00:00:00.000+00:00',
      endDate: '2024-08-27T00:00:00.000+00:00',
      status: 'To Be Confirmed by DNV',
      services: [
        {
          id: 0,
          name: 'ISO 9001:2015',
        },
      ],
      site: 'Opentech (Germany)',
      address: '789 Friedrichstraße, Berlin, Germany',
      city: 'Berlin',
      auditType: 'Follow-up Audit',
      leadAuditor: 'John Doe',
      siteRepresentative: 'Sara Connor',
      company: 'Opentech',
    },
    {
      scheduleId: 3,
      startDate: '2025-06-08T00:00:00.000+00:00',
      endDate: '2025-06-10T00:00:00.000+00:00',
      status: 'To Be Confirmed by DNV',
      services: [
        {
          id: 0,
          name: 'ISO 9001:2015',
        },
      ],
      site: 'Opentech (UK)',
      address: '123 Oxford St, London, UK',
      city: 'London',
      auditType: 'Follow-up Audit',
      leadAuditor: 'John Doe',
      siteRepresentative: 'Sara Connor',
      company: 'Opentech',
    },
    {
      scheduleId: 19,
      startDate: '2025-07-05T00:00:00.000+00:00',
      endDate: '2025-09-10T00:00:00.000+00:00',
      status: 'To Be Confirmed by DNV',
      services: [
        {
          id: 0,
          name: 'ISO 9001:2015',
        },
      ],
      site: 'Opentech (Estonia)',
      address: '456 Viru St, Tallinn, Estonia',
      city: 'Tallinn',
      auditType: 'Follow-up Audit',
      leadAuditor: 'John Doe',
      siteRepresentative: 'Sara Connor',
      company: 'Opentech',
    },
    {
      scheduleId: 20,
      startDate: '2025-08-08T00:00:00.000+00:00',
      endDate: '2025-09-10T00:00:00.000+00:00',
      status: 'To Be Confirmed',
      services: [
        {
          id: 0,
          name: 'ISO 9001:2015',
        },
      ],
      site: 'Opentech (UK)',
      address: '123 Oxford St, London, UK',
      city: 'London',
      auditType: 'Follow-up Audit',
      leadAuditor: 'John Doe',
      siteRepresentative: 'Sara Connor',
      company: 'Opentech',
    },
    {
      scheduleId: 21,
      startDate: '2025-08-08T00:00:00.000+00:00',
      endDate: '2025-10-21T00:00:00.000+00:00',
      status: 'To Be Confirmed',
      services: [
        {
          id: 0,
          name: 'ISO 9001:2015',
        },
      ],
      site: 'Opentech (UK)',
      address: '123 Oxford St, London, UK',
      city: 'London',
      auditType: 'Follow-up Audit',
      leadAuditor: 'John Doe',
      siteRepresentative: 'Sara Connor',
      company: 'Opentech',
    },
    {
      scheduleId: 22,
      startDate: '2025-08-08T00:00:00.000+00:00',
      endDate: '2025-09-19T00:00:00.000+00:00',
      status: 'To Be Confirmed',
      services: [
        {
          id: 0,
          name: 'ISO 9001:2015',
        },
      ],
      site: 'Opentech (UK)',
      address: '123 Oxford St, London, UK',
      city: 'London',
      auditType: 'Follow-up Audit',
      leadAuditor: 'John Doe',
      siteRepresentative: 'Sara Connor',
      company: 'Opentech',
    },
    {
      scheduleId: 23,
      startDate: '2025-08-08T00:00:00.000+00:00',
      endDate: '2025-08-19T00:00:00.000+00:00',
      status: 'To Be Confirmed',
      services: [
        {
          id: 0,
          name: 'ISO 9001:2015',
        },
      ],
      site: 'Opentech (UK)',
      address: '123 Oxford St, London, UK',
      city: 'London',
      auditType: 'Follow-up Audit',
      leadAuditor: 'John Doe',
      siteRepresentative: 'Sara Connor',
      company: 'Opentech',
    },
    {
      scheduleId: 24,
      startDate: '2025-08-08T00:00:00.000+00:00',
      endDate: '2025-11-10T00:00:00.000+00:00',
      status: 'To Be Confirmed',
      services: [
        {
          id: 0,
          name: 'ISO 9001:2015',
        },
      ],
      site: 'Opentech (UK)',
      address: '123 Oxford St, London, UK',
      city: 'London',
      auditType: 'Follow-up Audit',
      leadAuditor: 'John Doe',
      siteRepresentative: 'Sara Connor',
      company: 'Opentech',
    },
  ],
  isSuccess: true,
  message: '',
  errorCode: '',
};
