<ng-container *transloco="let t">
  <p-treeSelect
    selectionMode="checkbox"
    [filter]="hasSearch()"
    [options]="options()"
    [placeholder]="placeholder()"
    [scrollHeight]="scrollHeight"
    [(ngModel)]="selected"
    (onNodeSelect)="onChange($event)"
    (onNodeUnselect)="onChange($event)">
  </p-treeSelect>
</ng-container>